<template>
    <div class="card py-2">
        <div class="text-center mx-1 mb-1">
            <b-button-group size="sm" right variant="gradient-dark">
                <b-button
                    @click="onClickDate('Todo')"
                    :variant="
                        dateTextSelected === 'Todo'
                            ? 'gradient-primary'
                            : 'light'
                    "
                    >Todo</b-button
                >

                <b-button
                    @click="onClickDate('Hoy')"
                    :variant="
                        dateTextSelected === 'Hoy'
                            ? 'gradient-primary'
                            : 'light'
                    "
                    >Hoy</b-button
                >
                <b-button
                    @click="onClickDate('Últ. semana')"
                    :variant="
                        dateTextSelected === 'Últ. semana'
                            ? 'gradient-primary'
                            : 'light'
                    "
                    >Última semana</b-button
                >
                <b-button
                    @click="onClickDate('Últ. mes')"
                    :variant="
                        dateTextSelected === 'Últ. mes'
                            ? 'gradient-primary'
                            : 'light'
                    "
                    >Último mes</b-button
                >
                <b-button
                    @click="onClickDate('Últ. año')"
                    :variant="
                        dateTextSelected === 'Últ. año'
                            ? 'gradient-primary'
                            : 'light'
                    "
                    >Último año</b-button
                >
            </b-button-group>
        </div>
        <div class="d-flex justify-content-around mt-25">
            <div
                v-for="(indicador, index) in indicadores"
                class="position-relative indicador-component text-center border"
            >
                <div class="mt-3">
                    <p
                        class="font-weight-bolder font-large-2 mb-50 text-dark text-shadow-1"
                    >
                        {{ indicador.numero }}
                    </p>
                    <span class="font-small-2 m-0 p-0">
                        {{ indicador.nombre }}</span
                    >
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import dayjs from "dayjs";
import { ref, onMounted } from "@vue/composition-api";
export default {
    emits: ["date"],
    props: {
        indicadores: {
            type: Array,
            default: () => [],
        },
    },
    setup(props, context) {
        const dateTextSelected = ref("Todo");

        function onClickDate(date) {
            if (date === "Hoy") {
                context.emit("date", dayjs().format("DD-MM-YYYY"));
            }
            if (date === "Últ. semana") {
                context.emit(
                    "date",
                    dayjs().subtract(1, "week").format("DD-MM-YYYY")
                );
            }

            if (date === "Últ. mes") {
                context.emit(
                    "date",
                    dayjs().subtract(1, "month").format("DD-MM-YYYY")
                );
            }

            if (date === "Últ. año") {
                context.emit(
                    "date",
                    dayjs().subtract(1, "year").format("DD-MM-YYYY")
                );
            }

            if (date === "Todo") {
                context.emit("date", null);
            }
            dateTextSelected.value = date;
        }

        return { onClickDate, dateTextSelected };
    },
};
</script>
<style lang="css" scope>
.indicador-component {
    height: 130px;
    width: 130px;
    border: 1px solid #ebe9f1;
    border-radius: 50%;
}
</style>
